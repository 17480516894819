
<owl-carousel-o [options]="HomeSliderConfig" class="home-slider" [ngClass]="class">
	
	<ng-container *ngFor="let slider of sliders">
	  <ng-template carouselSlide>
	    <div class="home" [ngClass]="textClass" [ngStyle]="{'background-image': 'url('+env.mediaUrl+slider.ImageName+')'}">
	      <div class="container">
	        <div class="row">
	          <div class="col">
	            <div class="slider-contain">
	              <div>
	                <h4 translate>{{slider.title | translate}}</h4>
	                <h2 translate>{{slider.subTitle | translate}}</h2>
					
                    <a [href]="slider.Url" *ngIf="slider.Url"   class="btn btn-solid" [ngClass]="buttonClass">{{buttonText | translate}}</a>
	                <a [routerLink]="['/collections/all']"   *ngIf="!slider.Url"
	                   class="btn btn-solid" [ngClass]="buttonClass">
	                   {{buttonText | translate}}
	                </a>
	              </div>
	            </div>
	          </div>
	        </div>
	      </div>
	    </div>
	  </ng-template>
	</ng-container>
</owl-carousel-o>