<div *ngIf="!loader">
  <div class="img-wrapper">
    <div class="lable-block">
      <span class="lable3" *ngIf="!product.ImgName">new</span>
      <span class="lable4" *ngIf="!product.ImgName">on sale</span>
    </div>
    <div class="front">
      <a [routerLink]="['/products/', product.ItemName.replace(' ', '-')]">
        <img 
          [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" 
          [lazyLoad]="ImageSrc ? ImageSrc : ('https://lendensoft.s3.ap-northeast-1.amazonaws.com/'+product.ImgName?.split(',')[0])" 
          class="img-fluid lazy-loading" 
          alt="{{ product.ImgName?.alt }}" />
      </a>
    </div>
    <div class="back" *ngIf="onHowerChangeImage">
      <a [routerLink]="['/products/', product.ItemName.replace(' ', '-')]">
        <img [src]="ImageSrc ? ImageSrc : ('https://lendensoft.s3.ap-northeast-1.amazonaws.com/'+product.ImgName?.split(',')[1])" class="img-fluid lazy-loading" alt="{{ product.ImgName.split(',')[1] }}">
      </a>
    </div>
    <ul class="product-thumb-list" *ngIf="thumbnail">
      <li class="grid_thumb_img" [class.active]="ImageSrc == 'https://lendensoft.s3.ap-northeast-1.amazonaws.com/'+image" *ngFor="let image of product.ImgName.split(',')">
        <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage('https://lendensoft.s3.ap-northeast-1.amazonaws.com/'+image)">
         aaa <img [lazyLoad]="('https://lendensoft.s3.ap-northeast-1.amazonaws.com/'+image)">
        </a>
      </li>
    </ul>
    <div class="cart-info cart-wrap">
      <!-- <a href="javascript:void(0)" title="Add to cart" (click)="CartModal.openModal(product)"
        *ngIf="cartModal">
        <i class="ti-shopping-cart"></i>
      </a> -->
      <a href="javascript:void(0)" title="Add to cart" [ngClass]="{'disabled':product?.StockValue===0}" (click)="addToCart(product)">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
        <i class="ti-heart" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
        <i class="ti-search" aria-hidden="true"></i>
      </a>
    </div>
  </div>
  <div class="product-detail pt-2">
    <div>
      <ngb-rating [rate]="product['RatingAvg']" [max]="5"></ngb-rating>
      <a [routerLink]="['/products/', product?.ItemName.replace(' ', '-')]">
        <h6 translate>{{ product?.ItemName | titlecase }}</h6>
      </a>
      <p translate>{{ product?.LongDescription }}</p>
      <h4 translate>
        {{ product?.SellRate * currency?.price | discount:product | currency:currency?.currency:'symbol' }}
        <del *ngIf="product?.discount"><span class="money"> {{ product?.SellRate * currency?.price | currency:currency?.currency:'symbol' }}</span></del>
      </h4>
      <div class="pricebox">
        <h6 class="mrp-pricebox mb-0"><span class="mtp-title mx-1">M.R.P.</span><span class="mrp-price" [textContent]="product?.MrpRate| currency:currency?.currency:'symbol'"></span></h6>
        <span class="off-text" [textContent]="(product?.DiscountPer)?product?.DiscountPer+'%':''"></span>
    </div>
      <ul class="color-variant" *ngIf="Color(product?.ItemFrontAttributes).length">
        <li [class]="color" *ngFor="let color of Color(product?.ItemFrontAttributes)" [ngStyle]="{'background-color': color}"
          (click)="ChangeVariants(color, product)">
        </li>
      </ul>
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>

