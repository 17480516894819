import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  public filter: any;
  public collapse: boolean = true;
  @Output() categoryChange: EventEmitter<any> = new EventEmitter<any>();
  constructor(public productService: ProductService) {
    this.productService.getProductsFilter.subscribe(fil => this.filter = fil);
  }

  ngOnInit(): void {
  }

  get filterbyCategory() {
    const category = [...new Set(this.filter?.Category.map(product => product.CategoryName))];
    return category;
  }
  categoryChangeHandler(){
    this.categoryChange.emit();
  }

}
