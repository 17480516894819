

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { NavService } from './nav.service';
export interface HotellistModel {
  HotelId: number;
  PropertyId:    number;
  HotelName: string;
  ContactPerson: string;
  Address: string;
  CountryId: number;
  StateId: string;
  City: string;
  ZIPCode: string;
  ContactNo: string;
  FAXNo: string;
  Description: string;
  Email: string;
  Website: string;
  CreatedDateTime: Date;
  LastModifiedDateTime: Date;
  CreatedByUserId: number;
  LastModifiedByUserId: number;
  CreatedByIPAddress: string;
  LastModifiedByIPAddress: string;
  status: number;
  HotelGOLO: string;
  HotelNameLoge: string;
  tinno?: any;
  SmsName?: any;
  GstNo?: any;
  StateCode: string;
  PanNo?: any;
  ReverseCharge?: any;
  StateName?: any;
  OrgTypeID: number;
  BranchID: number;
  ClientCode: string;
  QRPaymentImage?: any;
  SignatureImage?: any;
  FaisNo?: any;
  IndustryType: number;
  SubOrgName: string;
  GroupId: number;
  isecommerce: number;
}

export interface MasterSettingslistModel {
  OrgId: number;
  Name: string;
  Flag: string;
  Value: number;
  FromTime: string;
  ToTime: string;
  ValueText: string;
  BranchID: number;
}

export interface DeptPointsSettinglistModel {
  Type: string;
  Total_Rupees: number;
  Point_On_Total_Rupess: number;
  Total_Points: number;
  Rupess_On_Total_Points: number;
}

export interface DeptPointsSettingCuslistModel {
  Type: string;
  Total_Rupees: number;
  Point_On_Total_Rupess: number;
  Total_Points: number;
  Rupess_On_Total_Points: number;
}

export interface EMailSettingMasterlistModel {
  ID: number;
  SMTPName: string;
  UserID: string;
  Password: string;
  Status: number;
  HotelID: number;
  PropertyId:    number;
  IS_Status: number;
  BranchID: number;
  SMTPPort: string;
  SMTPEncryption: string;
  EmailFromName: string;
}
export interface CompanyDetail {
  Hotellist_Model: HotellistModel;
  StripeKey?: string;
  MasterSettingslist_Model: MasterSettingslistModel[];
  DeptPointsSettinglist_Model: DeptPointsSettinglistModel[];
  DeptPointsSettingCuslist_Model: DeptPointsSettingCuslistModel[];
  EMailSettingMasterlist_Model: EMailSettingMasterlistModel[];
  SocialDetail: [];
}

export class IBlog {
  PropertyId: number;
  BlogId: number;
  commentbody: string;
  Name: string;
  Email: string;
  CreatedByUserId?: number;
  CreatedByIPAddress?: string;
  Id: number;
}
const state = {

}
@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  companyData: CompanyDetail;
  companyPages = new BehaviorSubject<[]>([]);
  SeoTags=[];
  env = environment;
  countries: [];
  constructor(
    public route: ActivatedRoute,
    public http: HttpClient, private cookieService: CookieService, private navServices: NavService
  ) {
    this.getCompanyDetail();
    this.getPageMeta();
    this.getCompanyPages();
    this.getCountries();
  }
  getheaders(): any {
    let httpOptions: any;
    if (this.cookieService.get('auth') != '' && this.cookieService.get('auth') != null) {
      const token = this.cookieService.get('auth');
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: ('Bearer ' + token),
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: ('Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiU3VwZXIgQWRtaW4iLCJuYW1laWQiOiIxIiwiVXNlcklkIjoiMSIsIkhvdGVsSWQiOiI2IiwiR3JvdXBJZCI6IjQiLCJHcm91cENvZGUiOiIxMDAzIiwiUm9sZU5hbWUiOiJTdXBlciBBZG1pbiIsIkZpbmFuQ2lhbFllYXJJRCI6IjAiLCJuYmYiOjE2NzQ4MDI1ODEsImV4cCI6MTY3NDg4ODk4MSwiaWF0IjoxNjc0ODAyNTgxfQ.4hGcrl4w0zQhmb3V8bOZMwBC3fSpgBJ3IGl-3fT50m8')
        })
      };
    }
    return httpOptions;
  }
  getCompanyDetail() {
    const promise = new Promise<void>((resolve, reject) => {
      this.http.get(this.env.apiUrl + 'api/Property/GetCompanyList',
        this.getheaders()).subscribe({
          next: (res: any) => {
            if (res.Data) {
              this.companyData = res.Data;
            }
            resolve(res.Data);
          },
          error: (err: any) => {
            reject(err);
          },
          complete: () => {
            
          },
        });
    });
    return promise;
  }
  getPageMeta(pageName:string='') {
    const promise = new Promise<void>((resolve, reject) => {
      this.http.get(this.env.apiUrl + 'api/Pages/PageMeta?PageName='+pageName,
        this.getheaders()).subscribe({
          next: (res: any) => {
            if (res.Data) {
              this.SeoTags = res.Data;
            }
            resolve();
          },
          error: (err: any) => {
            reject(err);
          },
          complete: () => {
            
          },
        });
    });
    return promise;
  }
  getCompanyPages() {
    this.http.get(this.env.apiUrl + 'api/Pages/PagesUtilityFront',
      this.getheaders()).subscribe({
        next: (res: any) => {
          if (res.Data) {
            this.companyPages = new BehaviorSubject<[]>(res.Data);
            let children = [];
            res.Data.forEach(element => {
              children.push({ path: '/pages/c/' + element.PageNamewithoutSpace, title: element.PageName || element.PageNamewithoutSpace, type: 'link', PageType: element.Type });
            });
            let pages = {
              title: 'pages', type: 'sub', active: false, children: children
            };
            this.navServices.updatePages(pages);
          }
        }
      });

  }
  getCompanyPageDetail(pagename: string) {
    return this.http.get(this.env.apiUrl + 'api/Pages/PagesInfoFront?PageName=' + encodeURIComponent(pagename),
      this.getheaders());

  }
  getCompanyBlogs() {
    return this.http.get(this.env.apiUrl + 'api/Blog/BlogMasterFront',
      this.getheaders());

  }
  getCompanyBlogDetail(id: any) {
    return this.http.get(this.env.apiUrl + 'api/Blog/BlogMasterFront?Id=' + id,
      this.getheaders());

  }
  BlogCommentMaster(id: any, BlogId: any) {
    return this.http.get(this.env.apiUrl + 'api/BlogComment/BlogCommentMaster?Id=' + id + "&BlogId=" + BlogId,
      this.getheaders());

  }
  BlogCounterHit(id: any) {
    return this.http.post(this.env.apiUrl + 'api/Blog/BlogCounterHit?BlogId=' + id,
      this.getheaders());

  }
  addblogcustomer(req: any) {
    return this.http.post(`${environment.apiUrl}api/BlogComment/BlogCommentMaster`, req, this.getheaders()).pipe(

    );
  }
  public getCountries() {
    this.http.get(this.env.apiUrl + 'api/Common/CountryMaster',
      this.getheaders()).subscribe({
        next: (res: any) => {
          if (res.Status == 0) {
            this.countries = res.Data;
          } else {
            alert('Error in Creating Order')
          }
        }
      });

  }
  getState(countryId: number) {
    return this.http.get(this.env.apiUrl + 'api/Common/StateMaster?Id=' + countryId,
      this.getheaders());

  }
  getCity(stateId: number) {
    return this.http.get(this.env.apiUrl + 'api/Common/CityMaster?Id=' + stateId,
      this.getheaders());

  }
  getArea(cityId: number) {
    return this.http.get(this.env.apiUrl + 'api/Common/AreaMaster?CityId=' + cityId,
      this.getheaders());

  }

  geHeaderTitleMaster(Id: number) {
    return this.http.get(this.env.apiUrl + 'api/Header/HeaderTitleMasterUser?Id=' + Id ,
      this.getheaders());
  } 
}


