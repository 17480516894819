import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, startWith, delay } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Product } from '../classes/product';
import { environment } from 'src/environments/environment';
import { Menu, NavService } from './nav.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  env = environment;
  currentUser:any={}
  public Currency = { name: 'Dollar', currency: 'USD', price: 1 }; // Default Currency


  constructor(private http: HttpClient,private route:Router,
    private toastrService: ToastrService, private cookieService: CookieService, private navServices: NavService) { 
      try{
        this.currentUser=JSON.parse(window.localStorage.getItem('currentUser')|| '{}');
      }
      catch{
        this.currentUser={};
      }
      
    }

    getFormheaders(): any {
      let httpOptions: any;
      if (this.cookieService.get('auth')) {
        var token =this.cookieService.get('auth');
        httpOptions = {
          headers: new HttpHeaders({
            'encType': 'multipart/form-data;',
            "Authorization": ('Bearer ' + token)
          })
        };
      } else {
        httpOptions = {
          headers: new HttpHeaders({
            'encType': 'multipart/form-data;'
          })
        };
      }
      return httpOptions;
    }
  getheaders(): any {
    let httpOptions: any;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8'
      })
    };
    return httpOptions;
  }
  loginUser(req:any) {
    this.http.post(this.env.apiUrl+'api/Authentication/Token', req).subscribe({
      next: (res: any) => {
        if(res.Status===0){
          this.cookieService.set('auth', res.Data.Access_Token, 36000, '/');
          this.currentUser=res?.Data?.Details;
          this.route.navigateByUrl('/home');
        }
        else{
          this.toastrService.error('User Name Password Invalid','Error');
        }
      
      }
    });
  }
  
  createUser(req: any) {
    return this.http.post(this.env.apiUrl + 'api/CustomerFront/CreateCustomer', req,
      this.getFormheaders());
  }
  logout(): void {
    this.cookieService.delete('auth','/');
    window.localStorage.clear();
    window.sessionStorage.clear();    
    this.route.navigateByUrl('/home');
    window.location.reload();
  }

  isUserLoggedIn(): boolean {
    if (this.cookieService.get('auth') != null && this.cookieService.get('auth')!='') {
      return true;
    }
    return false;
  }
  setUser(data) {
    window.localStorage.setItem('currentUser',JSON.stringify(data));
    this.currentUser=data;
  }
  getUserName() {
    if(this.isUserLoggedIn()){
      return (this.currentUser.FirstName || '')+' '+(this.currentUser.LastName || '');
    }else{
      return 'My Account';
    }
  }
  getCustomerID() {
    return this.currentUser.CustomerId;
  }
  getCustomerEmail(){
    return this.currentUser.Email;   
  }
  getCompanyName(){
    return this.currentUser.CompanyName;   
  }
  getMobileNo(){
    return this.currentUser.MobileNo;   
  }  
  getCustomerAddress(){
    return  this.http.get(this.env.apiUrl + '/api/CustomerAddress/GetCustomerAddress',
    this.getheaders());
  }


}
