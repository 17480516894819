<!--footer section -->
<footer [class]="class">
	<div class="newsletter-part" *ngIf="newsletter">
		<div class="container">
			<section class="small-section">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4 >{{Header2?.TitleName}}</h4>
         						 <p translate>{{Header2?.Description}}</p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form [formGroup]="subscribeForm"  (ngSubmit)="submitSubscribe()">
							<div class="form-group d-flex" >
								<input type="text" class="form-control" id="exampleFormControlInput"
									placeholder="Enter your email/phone" formControlName="Input">
								<button type="submit" class="btn btn-success black-btn">subscribe </button>
							</div>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div>
	
	<section class="section-b-space pb-4 foot-bg footer-bg-area padding-100">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-12 col-lg-6 col-md-6">
					<div class="footer-title footer-mobile-title d-none">
						<h4 class="d-none">about</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="env.mediaUrl+companyService?.companyData?.Hotellist_Model?.HotelNameLoge" class="img-fluid logo" alt="logo" title="logo" width="140" height="54">
						</div>
						<p class="mb-1 foot-logo-abt text-white d-none" [textContent]="companyService?.companyData?.Hotellist_Model?.Description | slice:0:200">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, </p>
						<div class="footer-social mt-3">
                            <ul>
                                <li *ngFor="let item of companyService.companyData?.SocialDetail; let i=index">
									<a *ngIf="item.Name=='Facebook'"  [href]="item.Url" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
									<a *ngIf="item.Name=='Instagram'" [href]="item.Url" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a> 
									<a *ngIf="item.Name=='LinkedIn'"  [href]="item.Url" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
									<a *ngIf="item.Name=='Pinterest'" [href]="item.Url" target="_blank"><i class="fa fa-pinterest" aria-hidden="true"></i></a>
									<a *ngIf="item.Name=='Reddit'"    [href]="item.Url" target="_blank"><i class="fa fa-reddit" aria-hidden="true"></i></a>
									<a *ngIf="item.Name=='Snapchat'"  [href]="item.Url" target="_blank"><i class="fa fa-snapchat" aria-hidden="true"></i></a> 
									<a *ngIf="item.Name=='TikTok'"    [href]="item.Url" target="_blank"><i class="fa fa-reddit" aria-hidden="true"></i></a>
									<a *ngIf="item.Name=='Twitter'"   [href]="item.Url" target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a>
									<a *ngIf="item.Name=='WhatsApp'"  [href]="item.Url" target="_blank"><i class="fa fa-whatsapp" aria-hidden="true"></i></a>
									<a *ngIf="item.Name=='YouTube'"   [href]="item.Url" target="_blank"><i class="fa fa-youtube" aria-hidden="true"></i></a> 								
								</li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col-6 col-lg-3 col-md-6">
					<div class="sub-title">
						<div class="footer-title">
                            <h4 translate>Brand</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>					
									<ng-container *ngIf="footerCategory && footerCategory.length===1">
							    	 <li *ngFor="let item of footerCategory[0].children; let i=index">
										<a [routerLink]="['/collections/all']" [queryParams]="{category:item.title.replaceAll(' ','-')}" [textContent]="item.title | translate">shipping & return</a></li> 
								   </ng-container>
								   <ng-container *ngIf="footerCategory && footerCategory.length>1">
									<li *ngFor="let item of footerCategory?.slice(0,7); let i=index"><a [routerLink]="['/collections/all']" [queryParams]="{category:item.title}" [textContent]="item.title | translate">shipping & return</a></li> 
								  </ng-container>
								</ul>
                        </div>
					</div>
				</div>
				
				<div class="col-6 col-lg-3 col-md-6">
					<div class="sub-title">
						<div class="footer-title">
                            <h4 translate>customer service</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li *ngFor="let item of footerPageMenuItems.children; let i=index"><a [routerLink]="item.path" [textContent]="item.title | translate">shipping & return</a></li>
								<li translate><a href="#">Blogs</a> </li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col-6 col-lg-3 col-md-6 d-none">
					<div class="sub-title">
						<div class="footer-title">
						    <h4 translate>store information</h4>
						</div>
						<div class="footer-contant">
							<ul class="contact-list">
								<li translate><i class="fa fa-map-marker me-2"></i>
								  <span [textContent]="companyService?.companyData?.Hotellist_Model?.Address"></span>
								</li>
								<li translate><i class="fa fa-phone me-2"></i>
								  <span [textContent]="companyService?.companyData?.Hotellist_Model?.ContactNo"></span>
								</li>
								<li translate><i class="fa fa-envelope-o me-2"></i>
								  <span [textContent]="companyService?.companyData?.Hotellist_Model?.Email"></span>
								</li>
								<li translate><i class="fa fa-fax me-2"></i>
								  <span [textContent]="companyService?.companyData?.Hotellist_Model?.FAXNo"></span>
								</li>
							  </ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}}  {{companyService?.companyData?.Hotellist_Model?.SubOrgName | uppercase}}</p>
                  </div>
                </div>
				<div class="col-xl-6 col-md-6 col-sm-12 d-none">
					<p class="text-center dd-by-p">Developed By <a class="dd-by" href="http://trueitproducts.com/" target="_blank">Truelogics</a></p>
				</div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="payment-card-bottom">
                    <ul>
                      <li translate>
                        <a><img src="../assets/images/icon/visa.png" alt="Payment" title="Visa" width="26" height="26"></a>
                      </li>
                      <li translate>
                        <a><img src="../assets/images/icon/mastercard.png" alt="Payment" title="Mastercard" width="26" height="26"></a>
                      </li>
                      <li translate>
                        <a><img src="../assets/images/icon/paypal.png" alt="Payment" title="Paypal" width="26" height="26"></a>
                      </li>
                      <li translate>
                        <a><img src="../assets/images/icon/american-express.png" alt="Payment" title="American Card" width="26" height="26"></a>
                      </li>
                      <li translate>
                        <a><img src="../assets/images/icon/discover.png" alt="Payment" title="Discover" width="26" height="26px"></a>
                      </li>
                    </ul>
                  </div>
                </div>
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->

