import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, startWith, delay } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Product } from '../classes/product';
import { environment } from 'src/environments/environment';
import { Menu, NavService } from './nav.service';
import * as _ from 'lodash';
import { WishList } from '../classes/wishlist';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  env = environment;

  constructor(private http: HttpClient,
    private toastrService: ToastrService, private cookieService: CookieService, private navServices: NavService) { }


  getheaders(): any {
    let httpOptions: any;
    if (this.cookieService.get('auth')) {
      const token = this.cookieService.get('auth');
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: ('Bearer ' + token),
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: ('Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiU3VwZXIgQWRtaW4iLCJuYW1laWQiOiIxIiwiVXNlcklkIjoiMSIsIkhvdGVsSWQiOiI2IiwiR3JvdXBJZCI6IjQiLCJHcm91cENvZGUiOiIxMDAzIiwiUm9sZU5hbWUiOiJTdXBlciBBZG1pbiIsIkZpbmFuQ2lhbFllYXJJRCI6IjAiLCJuYmYiOjE2NzQ4MDI1ODEsImV4cCI6MTY3NDg4ODk4MSwiaWF0IjoxNjc0ODAyNTgxfQ.4hGcrl4w0zQhmb3V8bOZMwBC3fSpgBJ3IGl-3fT50m8')
        })
      };
    }
    return httpOptions;
  }
  getFormheaders(): any {
    let httpOptions: any;
    if (this.cookieService.get('auth')) {
      var token =this.cookieService.get('auth');
      httpOptions = {
        headers: new HttpHeaders({
          'encType': 'multipart/form-data;',
          "Authorization": ('Bearer ' + token)
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'encType': 'multipart/form-data;'
        })
      };
    }
    return httpOptions;
  }
  getCustomerAddress() {
    return this.http.get(this.env.apiUrl + 'api/CustomerAddress/GetCustomerAddress',
      this.getheaders());
  }
  getCustomerDetail() {
    return this.http.get(this.env.apiUrl + 'api/CustomerFront/GetCustomerFrontById',
      this.getheaders());
  }
  addEditAddress(req:any) {
    return this.http.post(this.env.apiUrl + 'api/CustomerAddress/AddAddress',req,
      this.getheaders());
  }
  deleteAddress(req) {
    return this.http.delete(this.env.apiUrl + 'api/CustomerAddress/CustomerAddress?Id='+req,
      this.getheaders());
  }
  getOrderHistory() {
    return this.http.get(this.env.apiUrl + 'api/OrderCustomer/CustomerOrderHistory',
      this.getheaders());
  }
  getOrderHistoryDetail(OrderId:number) {
    return this.http.get(this.env.apiUrl + 'api/OrderCustomer/CustomerOrderDetail?OrderId='+OrderId,
      this.getheaders());
  }
  createWishList(req: WishList) {
    return this.http.post(this.env.apiUrl + 'api/WishList/WishList', req,
      this.getheaders());
  }

  getWishList() {
    return this.http.get(this.env.apiUrl + 'api/WishList/WishList',
      this.getheaders());
  }
  createUser(req: any) {
    return this.http.post(this.env.apiUrl + 'api/CustomerFront/CreateCustomer', req,
      this.getFormheaders());
  }
  logout(): void {
    this.cookieService.delete('auth', '/')
  }

  isUserLoggedIn(): boolean {
    if (this.cookieService.get('auth') != null) {
      return true;
    }
    return false;
  }
}
