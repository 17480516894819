import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HomeSlider } from '../../../shared/data/slider';
import * as _ from 'lodash';
@Component({
  selector: 'app-footer-slider',
  templateUrl: './footer-slider.component.html',
  styleUrls: ['./footer-slider.component.scss']
})
export class FooterSliderComponent implements OnInit {
  
  @Input() sliders: any[];
  @Input() class: string;
  @Input() textClass: string;
  @Input() category: string;
  @Input() buttonText: string;
  @Input() buttonClass: string;
  @Input() filterBanner: string;
  SliderList: any;
  constructor() { }
  env=environment;
  ngOnInit(): void {
    this.filterData(this.sliders);
  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.filterData(this.sliders);
  }

  public HomeSliderConfig: any = HomeSlider;
  filterData(slider) {
    if(!slider || _.isEmpty(slider)){
      this.SliderList =[];
      return;
    }
    this.SliderList = slider.filter((s) => {
      return s.BannerType == this.filterBanner;
    });
  }

}
