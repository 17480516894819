<!--footer section -->
<footer [ngClass]="class">
    <div class="dark-layout" *ngIf="mainFooter">
        <div class="container">
            <section class="section-b-space border-b">
                <div class="row footer-theme2">
                    <div class="col-lg-3">
                        <div class="footer-title footer-mobile-title">
                            <h4 translate>about</h4>
                        </div>
                        <div class="footer-contant">
                            <div class="footer-logo" id="footerlogo">
                                <img [src]="themeLogo"  alt="logo">
                            </div>
                            <p translate>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, </p>
                        </div>
                    </div>
                    <div class="col-lg-6 subscribe-wrapper">
                        <div class="subscribe-block">
                            <h2 translate>newsletter</h2>
                            <form >
                                <div class="form-group">
                                    <input type="text" class="form-control" id="exampleFormControlInput3" placeholder="Enter your email">
                                    <button type="submit" class="btn btn-solid ">subscribe</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="footer-title">
                            <h4 translate>store information</h4>
                        </div>
                        <div class="footer-contant">
                            <ul class="contact-details">
                                <li translate>Multikart Demo Store, Demo store
                                    India 345-659</li>
                                <li translate>Call Us: 123-456-7898</li>
                                <li translate>Email Us: <a href="javascript:void(0)">Support@Fiot.com</a></li>
                                <li translate>Fax: 123456</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <div class="dark-layout" *ngIf="subFooter">
        <div class="container">
            <section class="small-section">
                <div class="row footer-theme2">
                    <div class="col p-set">
                        <div class="footer-link">
                            <div class="sub-title">
								<div class="footer-title">
									<h4 translate>Categories</h4>
								</div>
								<div class="footer-contant">
									<ul>
										<li translate><a href="javascript:void(0)">Womens Fashion</a></li>
										<li translate><a href="javascript:void(0)">Mens Fashion</a></li>
										<li translate><a href="javascript:void(0)">Kids Fashion</a></li>
										<li translate><a href="javascript:void(0)">Featured</a></li>
									</ul>
								</div>
							</div>
                        </div>
                        <div class="footer-link-b">
                            <div class="sub-title">
								<div class="footer-title">
									<h4 translate>why we choose</h4>
								</div>
								<div class="footer-contant">
									<ul>
										<li translate><a href="javascript:void(0)">shipping & return</a></li>
										<li translate><a href="javascript:void(0)">secure shopping</a></li>
										<li translate><a href="javascript:void(0)">gallary</a></li>
										<li translate><a href="javascript:void(0)">affiliates</a></li>
										<li translate><a href="javascript:void(0)">contacts</a></li>
									</ul>
								</div>
							</div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <div class="sub-footer darker-subfooter">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p translate><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y' }} theme forest powered by pixelstrap</p>
                  </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="payment-card-bottom">
                    <ul>
                      <li translate>
                        <a href="javascript:void(0)">
                            <img src="assets/images/icon/visa.png" alt="">
                        </a>
                      </li>
                      <li translate>
                        <a href="javascript:void(0)">
                            <img src="assets/images/icon/mastercard.png" alt="">
                        </a>
                      </li>
                      <li translate>
                        <a href="javascript:void(0)">
                            <img src="assets/images/icon/paypal.png" alt="">
                        </a>
                      </li>
                      <li translate>
                        <a href="javascript:void(0)">
                            <img src="assets/images/icon/american-express.png" alt="">
                        </a>
                      </li>
                      <li translate>
                        <a href="javascript:void(0)">
                            <img src="assets/images/icon/discover.png" alt="">
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--footer section end -->