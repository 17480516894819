import { Component, OnInit, Injectable, PLATFORM_ID, Inject, SimpleChanges } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from "../../services/product.service";
import { Product } from "../../classes/product";
import { CartService } from '../../services/cart.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public products: Product[] = [];
  public search: boolean = false;
  totalItems=0;
  public languages = [{ 
    name: 'English',
    code: 'en'
  },{
    name: 'Hindi',
    code: 'hi'
  }];

  public currencies = [{
    name: 'Euro',
    currency: 'EUR',
    price: 0.90 // price of euro
  }, {
    name: 'Rupees',
    currency: 'INR',
    price: 70.93 // price of inr
  }, {
    name: 'Pound',
    currency: 'GBP',
    price: 0.78 // price of euro
  }, {
    name: 'Dollar',
    currency: 'USD',
    price: 1 // price of usd
  }]

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    public productService: ProductService,private cartService:CartService) {
    this.productService.cartItems.subscribe(response => {
      this.products = response;
      this.totalCount();
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.totalCount();
  }
  ngOnInit(): void {
    this.cartService.events$.forEach(event =>{
      
      this.productService.cartItems.subscribe(response => {
        this.products = response;
        this.totalCount();
      });
    });
  }

  searchToggle(){
    this.search = !this.search;
  }

  changeLanguage(code){
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code)
    }
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
    this.totalCount();
  }
  removeAttrCartItem(product: any) {
    this.productService.removeAttrCartItem(product);
    this.totalCount();
  }
  changeCurrency(currency: any) {
    this.productService.Currency = currency
  }
  totalCount(){
    this.totalItems=0;
    this.products.forEach((i)=>{
      if(i && i.ItemFrontAttributes && i?.ItemFrontAttributes?.length==0){
        this.totalItems=this.totalItems+1;
      }else{
          let totalChildItem =i.ItemFrontAttributes?.filter((f)=>{
              return f.Selected===true;
          }) || [];
          this.totalItems= this.totalItems+totalChildItem?.length||0;
      }
    });
  }
}
