


import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  globalPageSettings:any;
  constructor(
   
  ) {
  }
  getParamValueQueryString(paramName) {
    const url = window.location.href;
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }
  setGlobalPageSettings(data){
    this.globalPageSettings=data?.Settings || [];
  }
  getGlobalPageSettings(){
    return this.globalPageSettings;
  }
}
