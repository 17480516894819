<div class="modal-content quick-view-modal">
    <div class="modal-body">
        <section class="contact-page register-page section-b-space py-2">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <h3 translate>SHIPPING ADDRESS</h3>

                        <form class="theme-form" [formGroup]="shippingAddress">
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="name">First Name *</label>
                                    <input type="text" class="form-control"  placeholder="First Name"
                                        formControlName="FirstName" required="">
                                </div>
                                <div class="col-md-6">
                                    <label for="email">Last Name *</label>
                                    <input type="text" class="form-control" placeholder="Last Name"
                                        formControlName="LastName" required="">
                                </div>
                                <div class="col-md-6">
                                    <label for="name">Address *</label>
                                    <input type="text" class="form-control" id="address-two" placeholder="Address"
                                        formControlName="Address" required="">
                                </div>
                                <div class="col-md-6">
                                    <label for="email">Zip Code *</label>
                                    <input type="text" class="form-control" id="zip-code" placeholder="Zip"
                                        formControlName="PinCode" required="">
                                </div>
                                <div class="col-md-6 select_input">
                                    <label for="review">Country *</label>
                                    <select class="form-control" size="1" formControlName="CountryId"
                                        (change)="onCountryChange($event.target.value)">
                                        <option [ngValue]="0">Select... country</option>
                                        <option *ngFor="let item of companyService.countries;"
                                            [textContent]="item.CountryName | translate" [value]="item.CountryId">India</option>

                                    </select>
                                </div>

                                <div class="col-md-6 select_input">
                                    <label for="review">Region/State *</label>
                                    <select class="form-control form-select" formControlName="StateId"
                                        (change)="onStateChange($event.target.value)">
                                        <option [ngValue]="0">Select...</option>
                                        <option *ngFor="let item of stateList;" [textContent]="item.StateName | translate"
                                            [value]="item.StateId">India</option>

                                    </select>
                                </div>
                                <div class="col-md-6 select_input">
                                    <label for="review">City *</label>
                                    <select class="form-control form-select" formControlName="CityId">
                                        <option [ngValue]="0">Select...</option>
                                        <option *ngFor="let item of cityList;" [textContent]="item.CityName | translate"
                                            [value]="item.Id">India</option>

                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label for="review">Mobile No *</label>
                                    <input type="text" class="form-control" id="MobileNo" placeholder="Mobile No."
                                        formControlName="MobileNo" required="">
                                </div>
                                <div class="row mx-0">
                                    <div class="col-md-12 pull-right text-end px-0">
                                        <button class="btn btn-sm btn-primary py-2 px-3 border me-2" type="submit" (click)="saveAddress()">Save
                                          </button>
                                            <button class="m-l-5 btn btn-sm btn-solid border"  (click)="closeModalPop()"> Close </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>