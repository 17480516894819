<div class="icon-nav">
  <ul>
    <li class="onhover-div mobile-search search-widgets" id="search-widgets">
      <div (click)="searchToggle()">
        <img src="assets/images/icon/search.png" class="img-fluid" alt="search-img" title="Search" width="22" height="22">
        <i class="ti-search"></i>
      </div>
      <div id="search-overlay" class="search-overlay" [ngStyle]="{'display': search ? 'block' : 'none' }">
        <div>
          <span class="closebtn" title="Close Overlay" (click)="searchToggle()">×</span>
          <div class="overlay-content">
            <div class="container">
              <div class="row">
                <div class="col-xl-12">
                  <form>
                    <div class="form-group">
                      <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Search a Product">
                    </div>
                    <button type="submit" class="btn btn-primary"><i class="fa fa-search"></i></button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
    <li class="onhover-div mobile-setting">
      <div><img src="../assets/images/icon/setting.png" class="img-fluid" alt="seating" title="setting" width="18" height="18">
        <i class="ti-settings"></i></div>
      <div class="show-div setting">
        <h6 translate>language</h6>
        <ul>
          <li *ngFor="let language of languages">
            <a href="javascript:void(0)" (click)="changeLanguage(language.code)">{{ language.name }}</a>
          </li>
        </ul>       
      </div>
    </li>
    <li class="onhover-div mobile-cart">
      <div>
        <a [routerLink]="['/shop/cart']">
          <img src="../assets/images/icon/cart.png" class="img-fluid" alt="Cart" title="Cart" width="18" height="18">
          <i class="ti-shopping-cart"></i>
        </a>
      </div>
      <span class="cart_qty_cls">{{ totalItems }}</span>
      <ul class="show-div shopping-cart" *ngIf='!totalItems'>
        <h5 translate>Your cart is currently empty.</h5>
      </ul>
      <ul class="show-div shopping-cart" *ngIf='products && products.length'>
        <ng-container *ngFor="let product of products || []">
          <ng-container *ngIf="product?.ItemFrontAttributes && product?.ItemFrontAttributes?.length==0">
            <li translate>

              <div class="media">
                <a [routerLink]="['/products/', product.ItemName.replace(' ', '-')]">
                  <img class="me-3"
                    [src]="'https://lendensoft.s3.ap-northeast-1.amazonaws.com/'+(product?.ImgName || '').split(',')[0]"
                    [alt]="(product?.ImgName || '').split(',')[0]">
                </a>
                <div class="media-body">
                  <a [routerLink]="['/products/', product.ItemName.replace(' ', '-')]">
                    <h4 translate>{{ product?.ItemName }}</h4>
                  </a>
                  <h4>
                    <span translate>
                      {{ product?.quantity }} x
                      {{ product?.SellRate * productService?.Currency.price |
                      currency:productService?.Currency.currency:'symbol' }}
                    </span>
                  </h4>
                </div>
              </div>
              <div class="close-circle" (click)="removeItem(product)">
                <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
              </div>

            </li>
          </ng-container>
          <ng-container *ngIf="product?.ItemFrontAttributes && product?.ItemFrontAttributes?.length>0">
            <ng-container *ngFor="let attr of product?.ItemFrontAttributes; let i=index">
              <li *ngIf="attr.Selected">

                <div class="media">
                  <a [routerLink]="['/products/', attr.ItemName.replace(' ', '-')]">
                    <img class="me-3"
                      [src]="'https://lendensoft.s3.ap-northeast-1.amazonaws.com/'+(attr?.Images ||  'no-img.png').split(',')[0]"
                      [alt]="(attr?.Images || 'no-img.png')?.split(',')[0]">
                  </a>
                  <div class="media-body">
                    <a [routerLink]="['/products/', attr.ItemName.replace(' ', '-')]">
                      <h4 translate>{{ attr?.ItemName }}<br>{{attr?.ColorName| titlecase}}&nbsp; {{attr?.SizeName| titlecase}}</h4>
                    </a>
                    <h4>
                      <span translate>
                        {{ attr?.quantity }} x
                        {{ attr?.SellRate * productService?.Currency.price |
                        currency:productService?.Currency.currency:'symbol' }}
                      </span>
                    </h4>
                  </div>
                </div>
                <div class="close-circle" (click)="removeAttrCartItem(product)">
                  <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
                </div>

              </li>
            </ng-container>
          </ng-container>
        </ng-container>
        <li translate>
          <div class="total">
            <h5>subtotal : <span translate>{{ getTotal | async | currency:productService?.Currency.currency:'symbol' }}</span>
            </h5>
          </div>
        </li>
        <li translate>
          <div class="buttons">
            <a [routerLink]="['/shop/cart']" class="view-cart">view cart</a>
            <a [routerLink]="['/shop/checkout']" class="checkout">checkout</a>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</div>