
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CartService {
  env = environment;
  constructor(private http: HttpClient, private cookieService: CookieService,private router:Router) { }

  private _subject = new Subject<any>();
  getheaders(): any {
    let httpOptions: any;
    if (this.cookieService.get('auth')) {
      const token = this.cookieService.get('auth');
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: ('Bearer ' + token),
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8'
        })
      };
    }
    return httpOptions;
  }

  cartChange(event) {
    this.saveAbandonCart();
    this._subject.next(event);
  }

  public saveAbandonCart() {
    let cartData=[];
    try{
      const data=JSON.parse(localStorage.getItem('cartItems'));
      cartData=data;
    }catch{
      cartData=[];
    }

    let req = {
      "Status": 1,
      "PublicId": "12345",
      "Detail":JSON.stringify(cartData)
    };
    this.http.post<any[]>(this.env.apiUrl + 'api/Cart/AbandonCart', req,
      this.getheaders()).subscribe({});
  }
  public clearCartInDB(redirectNewSession=false) {
    let cartData=[];
    try{
      const data=JSON.parse(localStorage.getItem('cartItems'));
      cartData=data;
    }catch{
      cartData=[];
    }

    let req = {
      "Status": 1,
      "PublicId": "12345",
      "Detail":'[]'
    };
    this.http.post<any[]>(this.env.apiUrl + 'api/Cart/AbandonCart', req,
      this.getheaders()).subscribe({next:(data)=>{
        if(redirectNewSession){
          localStorage.clear();
          sessionStorage.clear();
          this.router.navigate(['/collections/all'])
            .then(() => {
              window.location.reload();
            });
        }
      }});
  }
  get events$() {
    return this._subject.asObservable();
  }

}
