

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
export interface HotellistModel {
    HotelId: number;
    PropertyId:    number;
    HotelName: string;
    ContactPerson: string;
    Address: string;
    CountryId: number;
    StateId: string;
    City: string;
    ZIPCode: string;
    ContactNo: string;
    FAXNo: string;
    Description: string;
    Email: string;
    Website: string;
    CreatedDateTime: Date;
    status: number;
    Tinno: string;
    SmsName: string;
    GstNo: string;
    StateCode: string;
    PanNo: string;
    ReverseCharge: string;
    StateName: string;
    OrgTypeID: number;
    BranchID: number;
    ClientCode: string;
    FaisNo: string;
    SubOrgName: string;
    IndustryType: number;
    GroupId: number;
}

export interface MasterSettingslistModel {
    OrgId: number;
    Name: string;
    Flag: string;
    Value: number;
    FromTime: string;
    ToTime: string;
    ValueText: string;
    BranchID: number;
}

export interface DeptPointsSettinglistModel {
    Type: string;
    Total_Rupees: number;
    Point_On_Total_Rupess: number;
    Total_Points: number;
    Rupess_On_Total_Points: number;
}

export interface DeptPointsSettingCuslistModel {
    Type: string;
    Total_Rupees: number;
    Point_On_Total_Rupess: number;
    Total_Points: number;
    Rupess_On_Total_Points: number;
}

export interface EMailSettingMasterlistModel {
    ID: number;
    SMTPName: string;
    UserID: string;
    Password: string;
    Status: number;
    HotelID: number;
    PropertyId:number;
    IS_Status: number;
    BranchID: number;
    SMTPPort: string;
    SMTPEncryption: string;
    EmailFromName: string;
}
export interface CompanyDetail {
    Hotellist_Model: HotellistModel;
    MasterSettingslist_Model: MasterSettingslistModel[];
    DeptPointsSettinglist_Model: DeptPointsSettinglistModel[];
    DeptPointsSettingCuslist_Model: DeptPointsSettingCuslistModel[];
    EMailSettingMasterlist_Model: EMailSettingMasterlistModel[];
}
@Injectable({
    providedIn: 'root'
})
export class PropertyService {
    propertyDetail:any;
    env=environment;
    constructor(
        public route: ActivatedRoute,
        public http: HttpClient,private cookieService:CookieService
    ) {
        this.getPropertyDetail();
    }
    getheaders(): any {
        let httpOptions: any;
        if (this.cookieService.get('auth')) {
          const token = this.cookieService.get('auth');
          httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json; charset=utf-8',
              Authorization: ('Bearer ' + token),
            })
          };
        } else {
          httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json; charset=utf-8'
            })
          };
        }
        return httpOptions;
      }
      getPropertyDetail(){
        this.http.get(this.env.apiUrl+'api/Property/PropertyUtility',
        this.getheaders()).subscribe({
            next: (res: any) => {
              if(res.Data){
                this.propertyDetail=res.Data;             
              }      
            }
      });
    }

}


