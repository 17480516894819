import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { ShopComponent } from './shop/shop.component';
import { PagesComponent } from './pages/pages.component';
import { AuthComponent } from './auth/auth.component';
import { DuplicateCallInterceptor } from './interceptors/duplicate-call.interceptor';
import { ApiInterceptor } from './interceptors/http-interceptor.service';
import { NgxStripeModule } from 'ngx-stripe';
import { CookieService } from 'ngx-cookie-service';
import { UtilityService } from './shared/services/util.service';

import { environment } from 'src/environments/environment';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
const initializeConfig = (utilityService: UtilityService, cookieService: CookieService, http: HttpClient) => {
  const getGlobalSettings = new Promise((resolve, reject) => {
    http.get(`${environment.apiUrl}api/PageSetting/GetGlobalSettings`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8'
      })
    }).toPromise()
      .then((result: any) => {
        if (result.Status == 0) {
          utilityService.setGlobalPageSettings(result.Data);
          return resolve(result.Data || []);
        } else {
          return resolve([]);
        }
        
      }).catch((err: HttpErrorResponse) => {
        return resolve([]);
      });
  });

  return async () => {
    const res = await Promise.all([getGlobalSettings]);
    return res;
  };
};
@NgModule({
  declarations: [
    AppComponent,
    ShopComponent,
    PagesComponent,
    AuthComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    NgxStripeModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: false,
      enableHtml: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule,
    AppRoutingModule
  ],
  providers: [
    UtilityService,
    CookieService,
    { provide: APP_INITIALIZER, useFactory: initializeConfig, deps: [UtilityService, CookieService, HttpClient], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DuplicateCallInterceptor, multi: true }, 
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
