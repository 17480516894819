<ng-template class="theme-modal" #sizeChart let-modal>
	<div class="modal-content">
	  <div class="modal-header">
	    <h5 class="modal-title" id="exampleModalLabel">{{product?.ItemName}}</h5>
	    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
	      <span aria-hidden="true">&times;</span>
	    </button>
	  </div>
	  <div class="modal-body">
		<img [src]="'https://lendensoft.s3.ap-northeast-1.amazonaws.com/'+product?.SizeChartImg" alt="images"
		class="img-fluid">
	   
	   </div>
	</div>
</ng-template>