import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sliderBannerType',
    pure: false
})

export class SliderBannerTypeFilterPipe implements PipeTransform {
    transform(items: any[], args: string): any {
        if (items && items.length>0 && args) {
            return items?.filter(item => item.BannerType === args);
        }
        else {
            return items;
        }
    }
}