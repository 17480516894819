import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subject, catchError, filter, of, tap, throwError } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
  export class DuplicateCallInterceptor implements HttpInterceptor {
  
    private activeCalls: Map<string, Subject<any>> = new Map();
    constructor(private router: Router) { }
    private handleAuthError(err: HttpErrorResponse,req:any): Observable<any> {
        //handle your auth error or rethrow
        this.activeCalls.delete(req.url);
        if (err.status === 401 || err.status === 403) {
            //navigate /delete cookies or whatever
            this.router.navigateByUrl(`/login`);
            // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
            return of(err.message); // or EMPTY may be appropriate here
        }
        return throwError(err);
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (this.activeCalls.has(request.url) && request.url.indexOf('GetItemFrontWithFilter')>-1) {
        const subject = this.activeCalls.get(request.url);
        return subject.asObservable();
      }
      this.activeCalls.set(request.url, new Subject<any>());
      return next.handle(request)
        .pipe(
          filter(res => res.type === HttpEventType.Response),
          tap(res => {
            const subject = this.activeCalls.get(request.url);
            if(subject){
              subject.next(res);
              subject.complete();
            }
            this.activeCalls.delete(request.url);
          }),
          catchError(x=> this.handleAuthError(x,request))
        )
    }
  }