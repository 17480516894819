import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input,
  Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Product } from "../../../classes/product";
import { ProductService } from '../../../services/product.service';
import { environment } from 'src/environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { CompanyService } from 'src/app/shared/services/company.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, OnDestroy  {
@Input('data') public data;
  env=environment;
  public closeResult: string;
  public modalOpen: boolean = false;
  shippingAddress:any;
  stateList=[];
  cityList=[];
  countryList=[];
  constructor(public companyService:CompanyService,private customerService:CustomerService,private formBuilder: FormBuilder, private modalService: NgbModal,public modal:NgbActiveModal) { }

  ngOnInit(): void {
    this.shippingAddress = this.formBuilder.group({
        "Id":[0,[]],
        "CityId":[0,[Validators.required]],
        "CountryId":[0,[Validators.required]],
        "StateId":[0,[Validators.required]],
        "Area":[,[]],
        "PinCode":[,[Validators.required]],
        "Status":[1,[]],
        "Address":[,[Validators.required]],
        "GstNo":[,[]],
        "FirstName":[,[Validators.required]],
        "LastName":[,[Validators.required]],
        "MobileNo":[],
        "AddressTypeId": 1
    });
    if(this.data){
      this.shippingAddress.patchValue(this.data);
      this.onCountryChange(this.data.CountryId);
    }
  }


  saveAddress(){
    if(!this.shippingAddress.valid){
      return false;
    }
    this.customerService.addEditAddress(this.shippingAddress.value).subscribe({
      next: (res: any) => {
        if (res.Status === 0) {
          this.modal.close('Success');
        } else {
        }

      }
    });
  }
  closeModalPop() {
      this.modal.close();
  }
  ngOnDestroy() {
    this.closeModalPop();
  }
  
  onCountryChange(countryId:number){
    
    this.companyService.getState(countryId).subscribe({
      next: (res: any) => {
        if (res.Data) {
          this.stateList = res.Data;
          if(this.data){
            this.onStateChange(this.data.StateId);
          }
        }
      }
    });
  }
  onStateChange(stateId:number){
    
    this.companyService.getCity(stateId).subscribe({
      next: (res: any) => {
        if (res.Data) {
          this.cityList = res.Data;
        }
      }
    });
  }
  
}
