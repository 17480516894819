<!-- Start Categories List -->
<div class="collection-collapse-block border-0" [class.open]="collapse">
    <h3 class="collapse-block-title" (click)="collapse = !collapse">Category</h3>
    <div class="collection-collapse-block-content">
        <div class="collection-brand-filter">
            <ul class="category-list">
                <li translate>
                    <a [routerLink]="['/collections/all']"  (click)="categoryChangeHandler()" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }">
                       All
                    </a>
                </li>
                <li *ngFor="let category of filterbyCategory">
                    <a [routerLink]="['/collections/',category.replaceAll(' ','-')]" (click)="categoryChangeHandler()"
                      routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }">
                        {{ category }}
                    </a>
                </li>
                <ng-container *ngFor="let tag of productService.ItemsTag">
                    <li *ngIf="tag.IsLeftMenu">
                      <!-- Sub -->
                      <a [routerLink]="['/collections/'+tag.ItemTagName]" class="nav-link">
                        {{ tag.ItemTagName | translate }}
                      </a>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
</div>
<!-- End Categories List -->