// loader-interceptor.service.ts
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpEventType
} from '@angular/common/http';
import { Observable, Subject, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { catchError, filter, tap } from 'rxjs/operators';
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    
    constructor(public router: Router, public cookieService: CookieService) { }

    private activeCalls: Map<string, Subject<any>> = new Map();
    private handleAuthError(err: HttpErrorResponse,removeUrl=''): Observable<any> {
      console.log('removeUrl',removeUrl)
       this.activeCalls.delete(removeUrl);
       console.log('this.activeCalls',this.activeCalls)
        //handle your auth error or rethrow
        if (err.status === 401 || err.status === 403) {
            //navigate /delete cookies or whatever
            this.cookieService.deleteAll('/');
            this.activeCalls.delete(removeUrl);
            if(!this.router.url.includes('/auth/login?returnUrl')){
              this.router.navigateByUrl(`/auth/login?returnUrl=${encodeURIComponent(this.router.url)}`);
            }
            // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
            return of(err.message); // or EMPTY may be appropriate here
        }
        return throwError(err);
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (this.activeCalls.has(request.url)) {
        const subject = this.activeCalls.get(request.url);
        return subject?.asObservable() || new Observable<HttpEvent<any>>();
      }
      this.activeCalls.set(request.url, new Subject<any>());
      return next.handle(request)
        .pipe(
          filter(res => res.type === HttpEventType.Response),
          tap(res => {
            const subject = this.activeCalls.get(request.url);
            subject?.next(res);
            subject?.complete();
            this.activeCalls.delete(request.url);
          }),
          catchError(x=>
            this.handleAuthError(x,request.url)
          )
        )
    }
}
